import Vue from "vue";

import { URL_API } from "../../settings/apiConnect";

export default {
  actions: {
    async getArchitectureType(context) {
      const response = await Vue.axios.get(`${URL_API}architectureType`);
      let data = response["data"];
      context.commit("updateArchitectureType", data);
    },
    async createArchitectureType(context, name) {
      const config = {};
      const body = {
        name: name
      };
      await Vue.axios.post(`${URL_API}architectureType/store`, body, config);
      context.dispatch("getArchitectureType");
    },
    async pushArchitectureType(context, { id, name }) {
      const config = {};
      const body = {
        id: id,
        name: name
      };
      await Vue.axios.post(`${URL_API}architectureType/update`, body, config);
      context.dispatch("getArchitectureType");
    },
    async destroyArchitectureType(context, id) {
      const config = {};
      const body = {
        id: id
      };
      await Vue.axios.post(`${URL_API}architectureType/destroy`, body, config);
      context.dispatch("getArchitectureType");
    }
  },
  mutations: {
    updateArchitectureType(state, data) {
      state.architectureType = data;
    }
  },
  state: {
    architectureType: []
  },
  getters: {
    architectureTypes(state) {
      state.architectureType.unshift({
        id: 10,
        name: "Парки и скверы",
        geometry_type: "Polygon"
      });
      return state.architectureType;
    }
  }
};

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import VueAxios from "vue-axios";
import VueLayers from "vuelayers";
import "vuelayers/dist/vuelayers.css";
import { XyzSource } from "vuelayers";
import VueTables from "vue-tables-2";
import VueExpandableImage from "vue-expandable-image";
import "elm-pep";

Vue.config.productionTip = false;
Vue.use(VueAxios, axios);
Vue.use(VueLayers);
Vue.use(XyzSource);
Vue.use(VueTables.ClientTable);
Vue.use(VueExpandableImage);

new Vue({
  router,
  store,
  vuetify,
  beforeCreate() {
    this.$store.commit("initToken");
    this.$store.dispatch("handleNoAuth");
  },
  render: h => h(App)
}).$mount("#app");

window.OneSignal = window.OneSignal || [];
window.OneSignal.push(() => {
  window.OneSignal.init({
    appId: "e6bdaa7e-fd11-412d-8449-fa1c1d37a813",
    notifyButton: {
      enable: true
    },
    allowLocalhostAsSecureOrigin: true
  });
  window.OneSignal.registerForPushNotifications();
});

export default {
  state: {
    redBookId: [
      27,
      37,
      62,
      102,
      115,
      158,
      160,
      162,
      163,
      167,
      166,
      165,
      164,
      168,
      169,
      170,
      171,
      172,
      173
    ]
  }
};

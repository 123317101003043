import Vue from "vue";

import { URL_API } from "../../settings/apiConnect";

export default {
  actions: {
    async getFountain(context) {
      let response = await Vue.axios.get(
        `${URL_API}architecture/getbytype?architecture_type_id=6`
      );
      let data = response["data"];
      context.commit("updateFountain", data);
      return data;
    }
  },
  mutations: {
    updateFountain(state, data) {
      state.fountain = data;
    }
  },
  state: {
    fountain: {}
  },
  getters: {
    allFountain(state) {
      return state.fountain;
    }
  }
};

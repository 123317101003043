import Vue from "vue";
import { URL_API } from "../../settings/apiConnect";

export default {
  actions: {
    async getEventLog(context, page) {
      const response = await Vue.axios.get(
        `${URL_API}event-log?data_per_page=10&order_by=desc&page=${page}`
      );
      let data = response["data"];
      context.commit("updateLog", data);
      return data;
    }
  },
  mutations: {
    updateLog(state, data) {
      state.eventLogData = data;
    }
  },
  state: {
    eventLogData: []
  },
  getters: {
    logData(state) {
      return state.eventLogData;
    }
  }
};

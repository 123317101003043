import Vue from "vue";

import { URL_API } from "../../settings/apiConnect";

export default {
  actions: {
    async getBushData(context) {
      const response = await Vue.axios.get(`${URL_API}bush`);
      let data = response["data"];
      context.commit("updateBushData", data);
    },
    async createBushData(context, name) {
      const config = {};
      const body = {
        name: name
      };
      await Vue.axios.post(`${URL_API}bush/store`, body, config);
      context.dispatch("getBushData");
    },
    async pushBushData(context, { id, name }) {
      const config = {};
      const body = {
        id: id,
        name: name
      };
      await Vue.axios.post(`${URL_API}bush/update`, body, config);
      context.dispatch("getBushData");
    },
    async destroyBushData(context, id) {
      const config = {};
      const body = {
        id: id
      };
      await Vue.axios.post(`${URL_API}bush/destroy`, body, config);
      context.dispatch("getBushData");
    }
  },
  mutations: {
    updateBushData(state, bushData) {
      state.bushData = bushData;
    }
  },
  state: {
    bushData: []
  },
  getters: {
    allBushesData(state) {
      return state.bushData;
    }
  }
};

import Vue from "vue";

import { URL_API } from "../../settings/apiConnect";

export default {
  actions: {
    async getBench(context) {
      let response = await Vue.axios.get(
        `${URL_API}architecture/getbytype?architecture_type_id=3`
      );
      let data = response["data"];
      context.commit("updateBench", data);
      return data;
    }
  },
  mutations: {
    updateBench(state, data) {
      state.bench = data;
    }
  },
  state: {
    bench: {}
  },
  getters: {
    allBench(state) {
      return state.bench;
    }
  }
};

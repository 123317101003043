import Vue from "vue";

import { URL_API } from "../../settings/apiConnect";

export default {
  actions: {
    async getVinesData(context) {
      const response = await Vue.axios.get(`${URL_API}vine`);
      let data = response["data"];
      context.commit("updateVinesData", data);
    },
    async createVineData(context, name) {
      const config = {};
      const body = {
        name: name
      };
      await Vue.axios.post(`${URL_API}vine/store`, body, config);
      context.dispatch("getVinesData");
    },
    async pushVineData(context, { id, name }) {
      const config = {};
      const body = {
        id: id,
        name: name
      };
      await Vue.axios.post(`${URL_API}vine/update`, body, config);
      context.dispatch("getVinesData");
    },
    async destroyVineData(context, id) {
      const config = {};
      const body = {
        id: id
      };
      await Vue.axios.post(`${URL_API}vine/destroy`, body, config);
      context.dispatch("getVinesData");
    }
  },
  mutations: {
    updateVinesData(state, VinesData) {
      state.vinesData = VinesData;
    }
  },
  state: {
    vinesData: []
  },
  getters: {
    allVinesData(state) {
      return state.vinesData;
    }
  }
};

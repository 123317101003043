import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Root",
    redirect: "/main/map"
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../screens/LoginScreen")
  },
  {
    path: "/main",
    name: "Main",
    redirect: "/main/map",
    component: () => import("../screens/MainScreen"),
    children: [
      {
        path: "action_log",
        meta: {
          operator: true,
          taxator: true,
          cityAkimat: true,
          districtAkimat: true
        },
        component: () => import("../tabs/ActionLogTab")
      },
      {
        path: "requests_portal",
        meta: {
          operator: true,
          taxator: true,
          cityAkimat: true,
          districtAkimat: true
        },
        component: () => import("../tabs/RequestsPortalTab")
      },
      {
        path: "facility_reports",
        meta: {
          operator: false,
          taxator: true,
          cityAkimat: false,
          districtAkimat: false
        },
        component: () => import("../tabs/FacilityReportsTab")
      },
      {
        path: "layers",
        meta: {
          operator: true,
          taxator: true,
          cityAkimat: true,
          districtAkimat: true
        },
        component: () => import("../tabs/LayersTab")
      },
      {
        path: "objects_map",
        meta: {
          operator: true,
          taxator: true,
          cityAkimat: true,
          districtAkimat: true
        },
        component: () => import("../tabs/TypesTab")
      },
      {
        path: "breeds",
        meta: {
          operator: true,
          taxator: true,
          cityAkimat: true,
          districtAkimat: true
        },
        component: () => import("../tabs/BreedsTab")
      },
      {
        path: "bushes",
        meta: {
          operator: true,
          taxator: true,
          cityAkimat: true,
          districtAkimat: true
        },
        component: () => import("../tabs/BushesTab")
      },
      {
        path: "flowers",
        meta: {
          operator: true,
          taxator: true,
          cityAkimat: true,
          districtAkimat: true
        },
        component: () => import("../tabs/FlowersTab")
      },
      {
        path: "vines",
        meta: {
          operator: true,
          taxator: true,
          cityAkimat: true,
          districtAkimat: true
        },
        component: () => import("../tabs/VinesTab")
      },
      {
        path: "flower_garden",
        meta: {
          operator: true,
          taxator: true,
          cityAkimat: true,
          districtAkimat: true
        },
        component: () => import("../tabs/FlowerGardenTab")
      },
      {
        path: "vermins",
        meta: {
          operator: true,
          taxator: true,
          cityAkimat: true,
          districtAkimat: true
        },
        component: () => import("../tabs/VerminsTab")
      },
      {
        path: "diseases",
        meta: {
          operator: true,
          taxator: true,
          cityAkimat: true,
          districtAkimat: true
        },
        component: () => import("../tabs/DiseasesTab")
      },
      {
        path: "statuses",
        meta: {
          operator: true,
          taxator: true,
          cityAkimat: true,
          districtAkimat: true
        },
        component: () => import("../tabs/StatusesTab")
      },
      {
        path: "events",
        meta: {
          operator: true,
          taxator: true,
          cityAkimat: true,
          districtAkimat: true
        },
        component: () => import("../tabs/EventsTab")
      },
      {
        path: "sanitary",
        meta: {
          operator: true,
          taxator: true,
          cityAkimat: true,
          districtAkimat: true
        },
        component: () => import("../tabs/SanitaryTab")
      },
      {
        path: "categories",
        meta: {
          operator: true,
          taxator: true,
          cityAkimat: true,
          districtAkimat: true
        },
        component: () => import("../tabs/CategoriesTab")
      },
      {
        path: "architectureTypes",
        meta: {
          operator: true,
          taxator: true,
          cityAkimat: true,
          districtAkimat: true
        },
        component: () => import("../tabs/ArchitectureTypesTab")
      },
      {
        path: "map",
        meta: {
          operator: false,
          taxator: false,
          cityAkimat: false,
          districtAkimat: false
        },
        component: () => import("../tabs/MapTab")
      },
      {
        path: "map2",
        meta: {
          operator: false,
          taxator: false,
          cityAkimat: false,
          districtAkimat: false
        },
        component: () => import("../tabs/MapTab2")
      },
      {
        path: "map3",
        meta: {
          operator: false,
          taxator: false,
          cityAkimat: false,
          districtAkimat: false
        },
        component: () => import("../tabs/MapTab3")
      },
      {
        path: "map4",
        meta: {
          operator: false,
          taxator: false,
          cityAkimat: false,
          districtAkimat: false
        },
        component: () => import("../tabs/MapTab4")
      },
      {
        path: "map5",
        meta: {
          operator: false,
          taxator: false,
          cityAkimat: false,
          districtAkimat: false
        },
        component: () => import("../tabs/MapTab5")
      },
      {
        path: "improvement",
        meta: {
          operator: false,
          taxator: false,
          cityAkimat: false,
          districtAkimat: true
        },
        component: () => import("../tabs/ImprovementTab")
      },
      {
        path: "districts",
        meta: {
          operator: true,
          taxator: true,
          cityAkimat: true,
          districtAkimat: true
        },
        component: () => import("../tabs/DistrictsTab")
      },
      {
        path: "clustergrid",
        meta: {
          operator: false,
          taxator: true,
          cityAkimat: true,
          districtAkimat: true
        },
        component: () => import("../tabs/ClusterGridTab")
      },
      {
        path: "taxatorgrid",
        meta: {
          operator: false,
          taxator: true,
          cityAkimat: true,
          districtAkimat: true
        },
        component: () => import("../tabs/TaxatorGridTab")
      },
      {
        path: "sanitarygrid",
        meta: {
          operator: false,
          taxator: true,
          cityAkimat: false,
          districtAkimat: true
        },
        component: () => import("../tabs/SanitaryGridTab")
      },
      {
        path: "users",
        meta: {
          operator: true,
          taxator: true,
          cityAkimat: true,
          districtAkimat: true
        },
        component: () => import("../tabs/UsersTab")
      },
      {
        path: "settings",
        meta: {
          operator: false,
          taxator: false,
          cityAkimat: false,
          districtAkimat: false
        },
        component: () => import("../settings/Settings")
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const currentUserRole = localStorage.getItem("userRole");
  const operator = to.matched.some(record => record.meta.operator);
  const taxator = to.matched.some(record => record.meta.taxator);
  const cityAkimat = to.matched.some(record => record.meta.cityAkimat);
  const districtAkimat = to.matched.some(record => record.meta.districtAkimat);

  if (to.name !== "Login" && localStorage.getItem("token") == null)
    next({ name: "Login" });
  else if (operator && currentUserRole === "2") next({ name: "Login" });
  else if (taxator && currentUserRole === "3") next({ name: "Login" });
  else if (cityAkimat && currentUserRole === "4") next({ name: "Login" });
  else if (districtAkimat && currentUserRole === "4") next({ name: "Login" });
  else next();
});

export default router;
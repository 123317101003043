import Vue from "vue";

import { URL_API } from "../../settings/apiConnect";

export default {
  actions: {
    async getArchitecture(context) {
      const response = await Vue.axios.get(`${URL_API}architecture`);
      let data = response["data"];
      context.commit("updateArchitecture", data);
    },
    async createArchitecture(context, { geometry, architecture_type_id }) {
      const config = {};
      let body = {};
      if (geometry) body.geometry = geometry;
      if (architecture_type_id)
        body.architecture_type_id = architecture_type_id;
      try {
        await Vue.axios.post(`${URL_API}architecture/store`, body, config);
        context.commit("updateMessage", "Данные успешно сохранены", {
          root: true
        });
      } catch (error) {
        context.commit("updateMessage", "Данные не сохранены", {
          root: true
        });
      }
      context.dispatch("getArchitecture");
    },
    async pushArchitecture(
      context,
      { id, geometry = null, architecture_type_id }
    ) {
      const config = {};
      const body = { id: id };
      if (geometry) body.geometry = geometry;
      if (architecture_type_id)
        body.architecture_type_id = architecture_type_id;
      try {
        await Vue.axios.post(`${URL_API}architecture/update`, body, config);
        context.commit("updateMessage", "Данные успешно сохранены", {
          root: true
        });
      } catch (error) {
        context.commit("updateMessage", "Данные не сохранены", {
          root: true
        });
      }
      context.dispatch("getArchitecture");
    },
    async destroyArchitecture(context, id) {
      const config = {};
      const body = {
        id: id
      };
      try {
        await Vue.axios.post(`${URL_API}architecture/destroy`, body, config);
        context.commit("updateMessage", "Данные успешно удалены", {
          root: true
        });
      } catch (error) {
        context.commit("updateMessage", "Данные не удалены", {
          root: true
        });
      }
      context.dispatch("getArchitecture");
    }
  },
  mutations: {
    updateArchitecture(state, data) {
      state.architecture = data;
    }
  },
  state: {
    architecture: {}
  },
  getters: {
    allArchitecture(state) {
      return state.architecture;
    }
  }
};

import Vue from "vue";

import { URL_API } from "../../settings/apiConnect";

export default {
  actions: {
    async getOSMPlayground(context) {
      let response = await Vue.axios.get(
        `${URL_API}architecture/getbytype?architecture_type_id=12`
      );
      let data = response["data"];
      context.commit("updateOSMPlayground", data);
      return data;
    }
  },
  mutations: {
    updateOSMPlayground(state, data) {
      state.osmPlayground = data;
    }
  },
  state: {
    osmPlayground: {}
  },
  getters: {
    allOSMPlayground(state) {
      return state.osmPlayground;
    }
  }
};

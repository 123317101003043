<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: "App"
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Montserrat");
.v-application {
  font-family: "Montserrat", sans-serif !important;
}
</style>

import Vue from "vue";

import { URL_API } from "../../settings/apiConnect";

export default {
  actions: {
    async getOSMFountains(context) {
      let response = await Vue.axios.get(
        `${URL_API}architecture/getbytype?architecture_type_id=11`
      );
      let data = response["data"];
      context.commit("updateOSMFountains", data);
      return data;
    }
  },
  mutations: {
    updateOSMFountains(state, data) {
      state.osmFountains = data;
    }
  },
  state: {
    osmFountains: {}
  },
  getters: {
    allOSMFountains(state) {
      return state.osmFountains;
    }
  }
};

import Vue from "vue";

import { URL_API } from "../../settings/apiConnect";

export default {
  actions: {
    async getPlantById(context, id) {
      const response = await Vue.axios.get(`${URL_API}plant/view/${id}`);
      return response["data"];
    },
    getPlantByExcel(context) {
      let districts = "";
      if (context.rootState.settings.ids_districts)
        districts = `district_id=${context.rootState.settings.ids_districts}&`;
      let types = "";
      if (context.rootState.settings.ids_types)
        types = `type_id=${context.rootState.settings.ids_types}&`;
      let breeds = "";
      if (context.rootState.settings.ids_breeds)
        breeds = `breed_id=${context.rootState.settings.ids_breeds}&`;
      let sanitary = "";
      if (context.rootState.settings.ids_sanitary)
        sanitary = `sanitary_id=${context.rootState.settings.ids_sanitary}&`;
      let statuses = "";
      if (context.rootState.settings.ids_statuses)
        statuses = `status_id=${context.rootState.settings.ids_statuses}&`;
      let events = "";
      if (context.rootState.settings.ids_events)
        events = `event_id=${context.rootState.settings.ids_events}&`;
      let vermin = "";
      if (context.rootState.settings.ids_vermin)
        vermin = `vermin_id=${context.rootState.settings.ids_vermin}&`;
      let diseases = "";
      if (context.rootState.settings.ids_diseases)
        diseases = `disease_id=${context.rootState.settings.ids_diseases}&`;
      let redbook = "";
      if (context.rootState.settings.redbookTarget)
        redbook = `redbook=${context.rootState.settings.redbookTarget}`;
      let layer = "";
      if (context.rootState.settings.layerTarget !== 0)
        layer = `layer_id=${context.rootState.settings.layerTarget}&`;
      Vue.axios({
        url: `${URL_API}plant/exportExcel?${districts}${types}${breeds}${sanitary}${statuses}${events}${vermin}${diseases}${layer}${redbook}`,
        method: "GET",
        responseType: "blob"
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    },
    async createPlantData(
      context,
      {
        geometry,
        layer_id,
        age,
        height,
        diameter,
        serial_number,
        sanitary_id,
        category_id,
        status_id,
        district_id,
        in_charge,
        planting_date,
        registry_date,
        quantity,
        length,
        area,
        vermin,
        diseases,
        events,
        type_id,
        breeds,
        lawn_name,
        lawn_type,
        bushes,
        vines,
        flower_garden_id,
        flowers,
        image
      }
    ) {
      const config = {};
      let body = {};
      if (geometry) body.geometry = geometry;
      if (layer_id) body.layer_id = layer_id;
      if (age) body.age = age;
      if (height) body.height = height;
      if (diameter) body.diameter = diameter;
      if (serial_number == null) serial_number = "";
      body.serial_number = serial_number;
      if (sanitary_id == null) sanitary_id = "";
      body.sanitary_id = sanitary_id;
      if (category_id == null) category_id = "";
      body.category_id = category_id;
      if (status_id == null) status_id = "";
      body.status_id = status_id;
      if (district_id == null) district_id = "";
      body.district_id = district_id;
      if (in_charge == null) in_charge = "";
      body.in_charge = in_charge;
      if (planting_date == null) planting_date = "";
      body.planting_date = planting_date;
      if (registry_date == null) registry_date = "";
      body.registry_date = registry_date;
      if (quantity == null) quantity = "";
      body.quantity = quantity;
      if (length) body.length = length;
      if (area) body.area = area;
      let arr = [];
      if (vermin) {
        vermin.forEach(el => {
          arr.push(el.id);
        });
        body.vermin = arr;
      }
      let arr2 = [];
      if (diseases) {
        diseases.forEach(el => {
          arr2.push(el.id);
        });
        body.diseases = arr2;
      }
      if (events) {
        let arr3 = [];
        events.forEach(el => {
          arr3.push(el.id);
        });
        body.events = arr3;
      }
      if (type_id) body.type_id = type_id;
      if (breeds) body.breeds = breeds;
      if (lawn_name) body.lawn_name = lawn_name;
      if (lawn_type) body.lawn_type = lawn_type;
      let arr4 = [];
      if (bushes) {
        bushes.forEach(el => {
          arr4.push(el.id);
        });
        body.bushes = arr4;
      }
      let arr6 = [];
      if (vines) {
        vines.forEach(el => {
          arr6.push(el.id);
        });
        body.vines = arr6;
      }
      if (flower_garden_id) body.flower_garden_id = flower_garden_id;
      let arr5 = [];
      if (flowers) {
        flowers.forEach(el => {
          arr5.push(el.id);
        });
        body.flowers = arr5;
      }
      if (image) body.image = image;
      let formData = new FormData();
      try {
        Object.entries(body).forEach(([key, value]) => {
          if (value != null) {
            if (Array.isArray(value)) {
              Object.entries(value).forEach(([key1, value1]) => {
                formData.append(key + "[" + key1 + "]", value1);
              });
            } else {
              formData.append(key, value);
            }
          }
        });
        await Vue.axios.post(`${URL_API}plant/store`, formData, config);
        context.commit("updateMessage", "Данные успешно сохранены", {
          root: true
        });
      } catch (error) {
        if (error.response.status === 422) {
          context.commit("updateMessage", "Доступ к данной области запрещен", {
            root: true
          });
        } else {
          context.commit("updateMessage", "Данные не сохранены", {
            root: true
          });
        }
      }
    },
    async pushPlantData(
      context,
      {
        id,
        geometry = null,
        age,
        height,
        diameter,
        serial_number,
        sanitary_id,
        category_id,
        status_id,
        district_id,
        in_charge,
        planting_date,
        registry_date,
        quantity,
        length,
        area,
        vermin,
        diseases,
        events,
        type_id,
        breeds,
        lawn_name,
        lawn_type,
        bushes,
        vines,
        flower_garden_id,
        flowers,
        image
      }
    ) {
      const config = {};
      const body = { id: id };
      if (geometry) body.geometry = geometry;
      if (age) body.age = age;
      if (height) body.height = height;
      if (diameter) body.diameter = diameter;
      if (serial_number == null) serial_number = "";
      body.serial_number = serial_number;
      if (sanitary_id == null) sanitary_id = "";
      body.sanitary_id = sanitary_id;
      if (category_id == null) category_id = "";
      body.category_id = category_id;
      if (status_id == null) status_id = "";
      body.status_id = status_id;
      if (district_id == null) district_id = "";
      body.district_id = district_id;
      if (in_charge == null) in_charge = "";
      body.in_charge = in_charge;
      if (planting_date == null) planting_date = "";
      body.planting_date = planting_date;
      if (registry_date == null) registry_date = "";
      body.registry_date = registry_date;
      if (quantity == null) quantity = "";
      body.quantity = quantity;
      body.length = length;
      if (area) body.area = area;
      let arr = [];
      if (vermin) {
        vermin.forEach(el => {
          arr.push(el.id);
        });
        body.vermin = arr;
      }
      let arr2 = [];
      if (diseases) {
        diseases.forEach(el => {
          arr2.push(el.id);
        });
        body.diseases = arr2;
      }
      if (events) {
        let arr3 = [];
        events.forEach(el => {
          arr3.push(el.id);
        });
        body.events = arr3;
      }
      if (type_id) body.type_id = type_id;
      if (breeds == null) breeds = "";
      body.breeds = breeds;
      if (lawn_name == null) lawn_name = "";
      body.lawn_name = lawn_name;
      if (lawn_type == null) lawn_type = "";
      body.lawn_type = lawn_type;
      let arr4 = [];
      if (bushes) {
        bushes.forEach(el => {
          arr4.push(el.id);
        });
        body.bushes = arr4;
      }
      let arr6 = [];
      if (vines) {
        vines.forEach(el => {
          arr6.push(el.id);
        });
        body.vines = arr6;
      }
      if (flower_garden_id == null) flower_garden_id = "";
      body.flower_garden_id = flower_garden_id;
      let arr5 = [];
      if (flowers) {
        flowers.forEach(el => {
          arr5.push(el.id);
        });
        body.flowers = arr5;
      }
      body.image = image;
      let formData = new FormData();
      try {
        Object.entries(body).forEach(([key, value]) => {
          if (value != null) {
            if (Array.isArray(value)) {
              Object.entries(value).forEach(([key1, value1]) => {
                formData.append(key + "[" + key1 + "]", value1);
              });
            } else {
              formData.append(key, value);
            }
          }
        });
        await Vue.axios.post(`${URL_API}plant/update`, formData, config);
        context.commit("updateMessage", "Данные успешно сохранены", {
          root: true
        });
      } catch (error) {
        if (error.response.status === 422) {
          context.commit("updateMessage", "Доступ к данной области запрещен", {
            root: true
          });
        } else {
          context.commit("updateMessage", "Данные не сохранены", {
            root: true
          });
        }
      }
    },
    async pushPlantGeometries(context, data) {
      console.dir(data);
      const response = await Vue.axios.post(
        `${URL_API}plant/updateGeometries`,
        data
      );
      return response.data;
    },
    async destroyPlantData(context, id) {
      const config = {};
      const body = {
        id: id
      };
      try {
        await Vue.axios.post(`${URL_API}plant/destroy`, body, config);
        context.commit("updateMessage", "Данные успешно удалены", {
          root: true
        });
      } catch (error) {
        if (error.response.status === 405) {
          context.commit("updateMessage", "Доступ к данной области запрещен", {
            root: true
          });
        } else {
          context.commit("updateMessage", "Данные не удалены", {
            root: true
          });
        }
      }
    },
    async removeImg(context, plant_id) {
      const config = {};
      const body = { plant_id: plant_id };
      await Vue.axios.post(`${URL_API}plant/remove-image`, body, config);
    }
  },
  mutations: {
    updatePlantsData(state, plantsData) {
      state.plantsData = plantsData;
    }
  },
  state: {
    plantsData: {}
  },
  getters: {
    allPlantsData(state) {
      return state.plantsData;
    }
  }
};

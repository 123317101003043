import Vue from "vue";

import { URL_API } from "../../settings/apiConnect";

export default {
  actions: {
    async getTypesData(context) {
      const response = await Vue.axios.get(`${URL_API}type`);
      let data = response["data"];
      context.commit("updateTypesData", data);
      context.commit("updateFilteredTypes", data);
    },
    async createTypeData(context, { name, geometry_type }) {
      const config = {};
      const body = {
        name: name,
        geometry_type: geometry_type
      };
      await Vue.axios.post(`${URL_API}type/store`, body, config);
      context.dispatch("getTypesData");
    },
    async pushTypeData(context, { id, name, geometry_type }) {
      const config = {};
      const body = {
        id: id,
        name: name,
        geometry_type: geometry_type
      };
      await Vue.axios.post(`${URL_API}type/update`, body, config);
      context.dispatch("getTypesData");
    },
    async destroyTypeData(context, id) {
      const config = {};
      const body = {
        id: id
      };
      await Vue.axios.post(`${URL_API}type/destroy`, body, config);
      context.dispatch("getTypesData");
    }
  },
  mutations: {
    updateTypesData(state, typesData) {
      state.typesData = typesData;
    }
  },
  state: {
    typesData: []
  },
  getters: {
    allTypesData(state) {
      return state.typesData;
    }
  }
};

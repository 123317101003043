import Vue from "vue";

import { URL_API } from "../../settings/apiConnect";

export default {
  actions: {
    async getDiseasesData(context) {
      const response = await Vue.axios.get(`${URL_API}disease`);
      let data = response["data"];
      context.commit("updateDiseasesData", data);
      context.commit("updateFilteredDiseases", data);
    },
    async createDiseaseData(context, name) {
      const config = {};
      const body = {
        name: name
      };
      await Vue.axios.post(`${URL_API}disease/store`, body, config);
      context.dispatch("getDiseasesData");
    },
    async pushDiseaseData(context, { id, name }) {
      const config = {};
      const body = {
        id: id,
        name: name
      };
      await Vue.axios.post(`${URL_API}disease/update`, body, config);
      context.dispatch("getDiseasesData");
    },
    async destroyDiseaseData(context, id) {
      const config = {};
      const body = {
        id: id
      };
      await Vue.axios.post(`${URL_API}disease/destroy`, body, config);
      context.dispatch("getDiseasesData");
    }
  },
  mutations: {
    updateDiseasesData(state, diseasesData) {
      state.diseasesData = diseasesData;
    }
  },
  state: {
    diseasesData: []
  },
  getters: {
    allDiseasesData(state) {
      return state.diseasesData;
    }
  }
};

import Vue from "vue";

import { URL_API } from "../../settings/apiConnect";

export default {
  actions: {
    async getSanitaryGrid(context) {
      const response = await Vue.axios.get(
        `${URL_API}sanitaryGrid/getbyfilter`
      );
      let data = response["data"];
      context.commit("updateSanitaryGrid", data);
      return data;
    },
    async getSanitaryGridById(context, id) {
      const response = await Vue.axios.get(`${URL_API}sanitaryGrid/view/${id}`);
      return response["data"];
    },
    async createSanitaryGrid(
      context,
      { geometry, name, color, company, comment, begin_date, end_date }
    ) {
      const config = {};
      const body = {};
      if (geometry) body.geometry = geometry;
      if (name) body.name = name;
      if (color) body.color = color;
      if (company) body.company = company;
      if (comment) body.comment = comment;
      if (begin_date) body.begin_date = begin_date;
      if (end_date) body.end_date = end_date;
      try {
        await Vue.axios.post(`${URL_API}sanitaryGrid/store`, body, config);
        context.dispatch("getSanitaryGrid");
        context.commit("updateMessage", "Данные успешно сохранены", {
          root: true
        });
      } catch (error) {
        context.commit("updateMessage", "Данные не сохранены", { root: true });
      }
    },
    async pushSanitaryGrid(
      context,
      {
        id,
        geometry = null,
        name,
        color,
        company,
        comment,
        begin_date,
        end_date
      }
    ) {
      const config = {};
      const body = { id: id };
      if (geometry) body.geometry = geometry;
      if (name) body.name = name;
      if (color) body.color = color;
      if (company) body.company = company;
      if (comment) body.comment = comment;
      if (begin_date) body.begin_date = begin_date;
      if (end_date) body.end_date = end_date;
      try {
        await Vue.axios.post(`${URL_API}sanitaryGrid/update`, body, config);
        context.dispatch("getSanitaryGrid");
        context.commit("updateMessage", "Данные успешно сохранены", {
          root: true
        });
      } catch (error) {
        context.commit("updateMessage", "Данные не сохранены", { root: true });
      }
    },
    async destroySanitaryGrid(context, id) {
      const config = {};
      const body = {
        id: id
      };
      try {
        await Vue.axios.post(`${URL_API}sanitaryGrid/destroy`, body, config);
        context.dispatch("getSanitaryGrid");
        context.commit("updateMessage", "Данные успешно удалены", {
          root: true
        });
      } catch (error) {
        context.commit("updateMessage", "Данные не удалены", { root: true });
      }
    }
  },
  mutations: {
    updateSanitaryGrid(state, sanitaryGrid) {
      state.sanitaryGrid = sanitaryGrid;
    }
  },
  state: {
    sanitaryGrid: {}
  },
  getters: {
    allSanitaryGrid(state) {
      return state.sanitaryGrid;
    }
  }
};

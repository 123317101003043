import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user";
import breeds from "./modules/breeds";
import flowers from "./modules/flowers";
import flowerGarden from "./modules/flowerGarden";
import vines from "./modules/vines";
import ageGroups from "./modules/ageGroups";
import categories from "./modules/categories";
import diseases from "./modules/diseases";
import events from "./modules/events";
import settings from "./modules/settings";
import statuses from "./modules/statuses";
import types from "./modules/types";
import vermins from "./modules/vermins";
import districts from "./modules/districts";
import users from "./modules/users";
import roles from "./modules/roles";
import plants from "./modules/plants";
import sanitary from "./modules/sanitary";
import layers from "./modules/layers";
import flowerCategory from "./modules/flowerCategory";
import selectedPlant from "./modules/plantView";
import taxatorgrid from "./modules/taxatorgrid";
import bushes from "./modules/bushes";
import clustergrid from "./modules/clustergrid";
import redBook from "./modules/redBook";
import message from "./modules/message";
import search from "./modules/searchTools";
import architectureTypes from "./modules/architectureTypes";
import architecture from "./modules/architecture";
import squares from "./modules/squares";
import playground from "./modules/playground";
import monument from "./modules/monument";
import bench from "./modules/bench";
import urn from "./modules/urn";
import lamp from "./modules/lamp";
import fountain from "./modules/fountain";
import other from "./modules/other";
import irrigation from "./modules/irrigation";
import paths from "./modules/paths";
import osmFountains from "./modules/osmFountains";
import osmPlayground from "./modules/osmPlayground";
import sanitaryGrid from "./modules/sanitaryGrid";
import appeal from "./modules/appeal";
import eventLog from "./modules/eventLog";
import comment from "./modules/comment";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    breeds,
    ageGroups,
    categories,
    diseases,
    events,
    settings,
    statuses,
    types,
    vermins,
    flowers,
    flowerCategory,
    flowerGarden,
    vines,
    districts,
    users,
    roles,
    plants,
    sanitary,
    layers,
    selectedPlant,
    taxatorgrid,
    bushes,
    clustergrid,
    redBook,
    message,
    search,
    architectureTypes,
    architecture,
    squares,
    playground,
    monument,
    bench,
    urn,
    lamp,
    fountain,
    other,
    irrigation,
    paths,
    osmFountains,
    osmPlayground,
    sanitaryGrid,
    appeal,
    eventLog,
    comment
  }
});

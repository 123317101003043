import Vue from "vue";

import { URL_API } from "../../settings/apiConnect";

export default {
  actions: {
    async getFlowerCategoryData(context) {
      const response = await Vue.axios.get(`${URL_API}flowerCategory`);
      let data = response["data"];
      context.commit("updateFlowerCategoryData", data);
    },
    async createFlowerCategoryData(context, name) {
      const config = {};
      const body = {
        name: name
      };
      await Vue.axios.post(`${URL_API}flowerCategory/store`, body, config);
      context.dispatch("getFlowerCategoryData");
    },
    async pushFlowerCategoryData(context, { id, name }) {
      const config = {};
      const body = {
        id: id,
        name: name
      };
      await Vue.axios.post(`${URL_API}flowerCategory/update`, body, config);
      context.dispatch("getFlowerCategoryData");
    },
    async destroyFlowerCategoryData(context, id) {
      const config = {};
      const body = {
        id: id
      };
      await Vue.axios.post(`${URL_API}flowerCategory/destroy`, body, config);
      context.dispatch("getFlowerCategoryData");
    }
  },
  mutations: {
    updateFlowerCategoryData(state, FlowerCategoryData) {
      state.flowerCategoryData = FlowerCategoryData;
    }
  },
  state: {
    flowerCategoryData: []
  },
  getters: {
    allFlowerCategoryData(state) {
      return state.flowerCategoryData;
    }
  }
};

import Vue from "vue";

import { URL_API } from "../../settings/apiConnect";

export default {
  actions: {
    async getLayersData(context) {
      const response = await Vue.axios.get(`${URL_API}layer`);
      let data = response["data"];
      context.commit("updateLayersData", data);
    },
    async createLayerData(context, name) {
      const config = {};
      const body = {
        name: name
      };
      await Vue.axios.post(`${URL_API}layer/store`, body, config);
      context.dispatch("getLayersData");
    },
    async pushLayerData(context, { id, name }) {
      const config = {};
      const body = {
        id: id,
        name: name
      };
      await Vue.axios.post(`${URL_API}layer/update`, body, config);
      context.dispatch("getLayersData");
    },
    async destroyLayerData(context, id) {
      const config = {};
      const body = {
        id: id
      };
      await Vue.axios.post(`${URL_API}layer/destroy`, body, config);
      context.dispatch("getLayersData");
    }
  },
  mutations: {
    updateLayersData(state, layersData) {
      let commonLayer = { id: 0, name: "Общий слой" };
      layersData.unshift(commonLayer);
      state.layersData = layersData;
    }
  },
  state: {
    layersData: []
  },
  getters: {
    allLayersData(state) {
      return state.layersData;
    }
  }
};

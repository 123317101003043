import Vue from "vue";

import { URL_API } from "../../settings/apiConnect";

export default {
  actions: {
    async createComment(context, { geometry, comment, user_id }) {
      const config = {};
      const body = {};
      if (geometry) body.geometry = geometry;
      if (comment) body.comment = comment;
      if (user_id) body.user_id = user_id;

      try {
        await Vue.axios.post(`${URL_API}mark/store`, body, config);
        context.commit("updateMessage", "Данные успешно сохранены", {
          root: true
        });
      } catch (error) {
        context.commit("updateMessage", "Данные не сохранены", { root: true });
      }
    },
    async pushComment(context, { id, geometry = null, comment, user_id }) {
      const config = {};
      const body = { id: id };
      if (geometry) body.geometry = geometry;
      if (comment) {
        body.comment = comment;
      } else {
        body.comment = "";
      }
      if (user_id) body.user_id = user_id;

      try {
        await Vue.axios.post(`${URL_API}mark/update`, body, config);
        context.commit("updateMessage", "Данные успешно сохранены", {
          root: true
        });
      } catch (error) {
        context.commit("updateMessage", "Данные не сохранены", { root: true });
      }
    },
    async destroyComment(context, id) {
      const config = {};
      const body = {
        id: id
      };
      try {
        await Vue.axios.post(`${URL_API}mark/destroy`, body, config);
        context.commit("updateMessage", "Данные успешно удалены", {
          root: true
        });
      } catch (error) {
        context.commit("updateMessage", "Данные не удалены", { root: true });
      }
    }
  },
  mutations: {},
  state: {}
};

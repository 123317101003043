import Vue from "vue";

import { URL_API } from "../../settings/apiConnect";

export default {
  actions: {
    objectType(context, { object_id }) {
      context.commit("setObjectType", object_id);
      context.dispatch("getAppeals");
    },
    async getAppeals(context) {
      const response = await Vue.axios.get(`${URL_API}appeal/getbyfilter?object_type=${context.state.object_id}`);
      let data = response["data"];
      context.commit("updateAppeals", data);
    },
    async getAppealsByPlant(context, plant_id) {
      const response = await Vue.axios.get(
        `${URL_API}appeal/get-by-plant?plant_id=${plant_id}`
      );
      return response["data"];
    },
    async createAppeal(
      context,
      { full_name, email, phone, body, image, plant_id }
    ) {
      const config = {};
      let formBody = {};
      if (full_name) formBody.full_name = full_name;
      if (email) formBody.email = email;
      if (phone) formBody.phone = phone;
      if (body) formBody.body = body;
      if (image) formBody.image = image;
      if (plant_id) formBody.plant_id = plant_id;

      let formData = new FormData();
      try {
        Object.entries(formBody).forEach(([key, value]) => {
          formData.append(key, value);
        });
        await Vue.axios.post(`${URL_API}appeal/create`, formData, config);
        context.dispatch("getAppeals");
        context.commit("updateMessage", "Заявка отправлена", {
          root: true
        });
      } catch (error) {
        context.commit("updateMessage", "Заявка не создана", {
          root: true
        });
      }
    },
    async updateAppeal(context, { id, body, reply, status }) {
      const config = {};
      let formBody = { id: id };
      formBody.body = body;
      formBody.reply = reply;
      formBody.status = status;
      try {
        await Vue.axios.post(`${URL_API}appeal/update`, formBody, config);
        context.dispatch("getAppeals");
        context.commit("updateMessage", "Заявка отправлена", {
          root: true
        });
      } catch (error) {
        context.commit("updateMessage", "Заявка не создана", {
          root: true
        });
      }
    },
    async destroyAppeal(context, id) {
      await Vue.axios.delete(`${URL_API}appeal/destroy?id=${id}`);
      context.dispatch("getAppeals");
    }
  },
  mutations: {
    setObjectType(state, data) {
      state.object_id = data;
    },
    updateAppeals(state, data) {
      state.appeals = data;
    }
  },
  state: {
    object_id: 1,
    appeals: []
  },
  getters: {
    allAppeals(state) {
      return state.appeals;
    }
  }
};

import Vue from "vue";

import { URL_API } from "../../settings/apiConnect";

export default {
  actions: {
    async getAgeGroupsData(context) {
      const response = await Vue.axios.get(`${URL_API}ageGroup`);
      let data = response["data"];
      context.commit("updateAgeGroupsData", data);
    },
    async createAgeGroupData(
      context,
      { groupFrom, groupTo, ageFrom, ageTo, age, name }
    ) {
      const config = {};
      const body = {
        name: name,
        age: age,
        age_from: ageFrom,
        age_to: ageTo,
        group_from: groupFrom,
        group_to: groupTo
      };
      await Vue.axios.post(`${URL_API}ageGroup/store`, body, config);
      context.dispatch("getAgeGroupsData");
    },
    async destroyAgeGroupData(context, id) {
      const config = {};
      const body = {
        id: id
      };
      await Vue.axios.post(`${URL_API}ageGroup/destroy`, body, config);
      context.dispatch("getAgeGroupsData");
    }
  },
  mutations: {
    updateAgeGroupsData(state, ageGroupsData) {
      state.ageGroupsData = ageGroupsData;
    }
  },
  state: {
    ageGroupsData: []
  },
  getters: {
    allAgeGroupsData(state) {
      return state.ageGroupsData;
    }
  }
};

import Vue from "vue";

import { URL_API } from "../../settings/apiConnect";

export default {
  actions: {
    async getSanitaryData(context) {
      const response = await Vue.axios.get(`${URL_API}sanitary`);
      let data = response["data"];
      context.commit("updateSanitaryData", data);
      context.commit("updateFilteredSanitary", data);
    },
    async createSanitaryData(context, name) {
      const config = {};
      const body = {
        name: name
      };
      await Vue.axios.post(`${URL_API}sanitary/store`, body, config);
      context.dispatch("getSanitaryData");
    },
    async pushSanitaryData(context, { id, name }) {
      const config = {};
      const body = {
        id: id,
        name: name
      };
      await Vue.axios.post(`${URL_API}sanitary/update`, body, config);
      context.dispatch("getSanitaryData");
    },
    async destroySanitaryData(context, id) {
      const config = {};
      const body = {
        id: id
      };
      await Vue.axios.post(`${URL_API}sanitary/destroy`, body, config);
      context.dispatch("getSanitaryData");
    }
  },
  mutations: {
    updateSanitaryData(state, sanitaryData) {
      state.sanitaryData = sanitaryData;
    }
  },
  state: {
    sanitaryData: []
  },
  getters: {
    allSanitaryData(state) {
      return state.sanitaryData;
    }
  }
};

import Vue from "vue";

import { URL_API } from "../../settings/apiConnect";

export default {
  actions: {
    async getClusterGrid(context) {
      const response = await Vue.axios.get(`${URL_API}clustergrid`);
      let data = response["data"];
      context.commit("updateClusterGrid", data);
      return data;
    },
    async createClusterGrid(context, { geometry, name, color }) {
      const config = {};
      const body = {};
      if (geometry) body.geometry = geometry;
      if (name) body.name = name;
      if (color) body.color = color;

      try {
        await Vue.axios.post(`${URL_API}clustergrid/store`, body, config);
        context.dispatch("getClusterGrid");
        context.commit("updateMessage", "Данные успешно сохранены", {
          root: true
        });
      } catch (error) {
        context.commit("updateMessage", "Данные не сохранены", { root: true });
      }
    },
    async pushClusterGrid(context, { id, geometry = null, name, color }) {
      const config = {};
      const body = { id: id };
      if (geometry) body.geometry = geometry;
      if (name) {
        body.name = name;
      } else {
        body.name = "";
      }
      if (color) body.color = color;

      try {
        await Vue.axios.post(`${URL_API}clustergrid/update`, body, config);
        context.dispatch("getClusterGrid");
        context.commit("updateMessage", "Данные успешно сохранены", {
          root: true
        });
      } catch (error) {
        context.commit("updateMessage", "Данные не сохранены", { root: true });
      }
    },
    async destroyClusterGrid(context, id) {
      const config = {};
      const body = {
        id: id
      };
      try {
        await Vue.axios.post(`${URL_API}clustergrid/destroy`, body, config);
        context.dispatch("getClusterGrid");
        context.commit("updateMessage", "Данные успешно удалены", {
          root: true
        });
      } catch (error) {
        context.commit("updateMessage", "Данные не удалены", { root: true });
      }
    }
  },
  mutations: {
    updateClusterGrid(state, clusterGrid) {
      state.clusterGrid = clusterGrid;
    }
  },
  state: {
    clusterGrid: {}
  },
  getters: {
    allClusterGrid(state) {
      return state.clusterGrid;
    }
  }
};

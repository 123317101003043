export default {
  state: {
    searchByID: false,
    searchByCoord: false,
    searchByAddress: false,
    searchBySerial: false,
    serialData: [],
    serialPassport: false
  },
  mutations: {
    updateSrchID(state, data) {
      state.searchByID = data;
    },
    updateSrchCoor(state, data) {
      state.searchByCoord = data;
    },
    updateSrchAdd(state, data) {
      state.searchByAddress = data;
    },
    updateSrchSerial(state, data) {
      state.searchBySerial = data;
    },
    updateSerialData(state, data) {
      state.serialData = data;
    }
  }
};

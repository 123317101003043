import Vue from "vue";

import { URL_API } from "../../settings/apiConnect";

export default {
  actions: {
    async getUrn(context) {
      let response = await Vue.axios.get(
        `${URL_API}architecture/getbytype?architecture_type_id=4`
      );
      let data = response["data"];
      context.commit("updateUrn", data);
      return data;
    }
  },
  mutations: {
    updateUrn(state, data) {
      state.urn = data;
    }
  },
  state: {
    urn: {}
  },
  getters: {
    allUrn(state) {
      return state.urn;
    }
  }
};

import Vue from "vue";

import { URL_API } from "../../settings/apiConnect";

export default {
  actions: {
    async getFlowersData(context) {
      const response = await Vue.axios.get(`${URL_API}flower`);
      let data = response["data"];
      context.commit("updateFlowersData", data);
    },
    async createFlowerData(context, { name, flower_category_id }) {
      const config = {};
      const body = {
        name: name,
        flower_category_id: flower_category_id
      };
      await Vue.axios.post(`${URL_API}flower/store`, body, config);
      context.dispatch("getFlowersData");
    },
    async pushFlowerData(context, { id, name, flower_category_id }) {
      const config = {};
      const body = {
        id: id,
        name: name,
        flower_category_id: flower_category_id
      };
      await Vue.axios.post(`${URL_API}flower/update`, body, config);
      context.dispatch("getFlowersData");
    },
    async destroyFlowerData(context, id) {
      const config = {};
      const body = {
        id: id
      };
      await Vue.axios.post(`${URL_API}flower/destroy`, body, config);
      context.dispatch("getFlowersData");
    }
  },
  mutations: {
    updateFlowersData(state, FlowersData) {
      state.FlowersData = FlowersData;
    }
  },
  state: {
    FlowersData: []
  },
  getters: {
    allFlowersData(state) {
      return state.FlowersData;
    }
  }
};

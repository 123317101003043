import "@fortawesome/fontawesome-free/css/all.css";
import Vue from "vue";
import Vuetify from "vuetify/lib";
import ru from "vuetify/es5/locale/ru";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdiSvg" // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: "#67CE57", //зеленый
        secondary: "#CCCCCC", //серый
        accent: "#82B1FF", //синий
        error: "#FF5252", //красный
        info: "#2196F3", //синий
        success: "#67CE57", //зеленый
        warning: "#FFC107", //оранжевый
        appbar: "#FFFFFF" //белый
      },
      dark: {
        primary: "#67CE57", //зеленый
        secondary: "#CCCCCC", //серый
        accent: "#82B1FF", //синий
        error: "#FF5252", //красный
        info: "#2196F3", //синий
        success: "#67CE57", //зеленый
        warning: "#FFC107", //оранжевый
        appbar: "#272727" //черный
      }
    }
  },
  lang: {
    locales: { ru },
    current: "ru"
  }
});

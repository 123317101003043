import Vue from "vue";

import { URL_API } from "../../settings/apiConnect";

export default {
  actions: {
    async getVerminsData(context) {
      const response = await Vue.axios.get(`${URL_API}vermin`);
      let data = response["data"];
      context.commit("updateVerminsData", data);
      context.commit("updateFilteredVermin", data);
    },
    async createVerminData(context, name) {
      const config = {};
      const body = {
        name: name
      };
      await Vue.axios.post(`${URL_API}vermin/store`, body, config);
      context.dispatch("getVerminsData");
    },
    async pushVerminData(context, { id, name }) {
      const config = {};
      const body = {
        id: id,
        name: name
      };
      await Vue.axios.post(`${URL_API}vermin/update`, body, config);
      context.dispatch("getVerminsData");
    },
    async destroyVerminData(context, id) {
      const config = {};
      const body = {
        id: id
      };
      await Vue.axios.post(`${URL_API}vermin/destroy`, body, config);
      context.dispatch("getVerminsData");
    }
  },
  mutations: {
    updateVerminsData(state, verminsData) {
      state.verminsData = verminsData;
    }
  },
  state: {
    verminsData: []
  },
  getters: {
    allVerminsData(state) {
      return state.verminsData;
    }
  }
};

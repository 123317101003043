import Vue from "vue";

import { URL_API } from "../../settings/apiConnect";

export default {
  actions: {
    async getStatusesData(context) {
      const response = await Vue.axios.get(`${URL_API}status`);
      let data = response["data"];
      context.commit("updateStatusesData", data);
      context.commit("updateFilteredStatuses", data);
    },
    async createStatusData(context, name) {
      const config = {};
      const body = {
        name: name
      };
      await Vue.axios.post(`${URL_API}status/store`, body, config);
      context.dispatch("getStatusesData");
    },
    async pushStatusData(context, { id, name }) {
      const config = {};
      const body = {
        id: id,
        name: name
      };
      await Vue.axios.post(`${URL_API}status/update`, body, config);
      context.dispatch("getStatusesData");
    },
    async destroyStatusData(context, id) {
      const config = {};
      const body = {
        id: id
      };
      await Vue.axios.post(`${URL_API}status/destroy`, body, config);
      context.dispatch("getStatusesData");
    }
  },
  mutations: {
    updateStatusesData(state, statusesData) {
      state.statusesData = statusesData;
    }
  },
  state: {
    statusesData: []
  },
  getters: {
    allStatusesData(state) {
      return state.statusesData;
    }
  }
};

import Vue from "vue";

import { URL_API } from "../../settings/apiConnect";

export default {
  actions: {
    async getSquares(context) {
      const response = await Vue.axios.get(`${URL_API}square`);
      let data = response["data"];
      context.commit("updateSquares", data);
      return data;
    },
    async getSquaresById(context, id) {
      const response = await Vue.axios.get(`${URL_API}square/view/${id}`);
      return response["data"];
    },
    async createSquares(
      context,
      {
        geometry,
        name,
        address,
        area,
        year,
        plant_amount,
        foliage_amount,
        pine_amount,
        bush_amount,
        hedge_length,
        lawn_area,
        garden_area,
        architecture_amount,
        fountain_amount,
        beautification_amount,
        bench_amount,
        trashcan_amount,
        lamp_amount,
        irrigation_length,
        road_length,
        company,
        district_id
      }
    ) {
      const config = {};
      const body = {};
      if (geometry) body.geometry = geometry;
      if (name) body.name = name;
      if (address) body.address = address;
      if (area) body.area = area;
      if (year) body.year = year;
      if (plant_amount) body.plant_amount = plant_amount;
      if (foliage_amount) body.foliage_amount = foliage_amount;
      if (pine_amount) body.pine_amount = pine_amount;
      if (bush_amount) body.bush_amount = bush_amount;
      if (hedge_length) body.hedge_length = hedge_length;
      if (lawn_area) body.lawn_area = lawn_area;
      if (garden_area) body.garden_area = garden_area;
      if (architecture_amount) body.architecture_amount = architecture_amount;
      if (fountain_amount) body.fountain_amount = fountain_amount;
      if (beautification_amount)
        body.beautification_amount = beautification_amount;
      if (bench_amount) body.bench_amount = bench_amount;
      if (trashcan_amount) body.trashcan_amount = trashcan_amount;
      if (lamp_amount) body.lamp_amount = lamp_amount;
      if (irrigation_length) body.irrigation_length = irrigation_length;
      if (road_length) body.road_length = road_length;
      if (company) body.company = company;
      if (district_id) body.district_id = district_id;
      console.dir(body);
      try {
        await Vue.axios.post(`${URL_API}square/store`, body, config);
        context.commit("updateMessage", "Данные успешно сохранены", {
          root: true
        });
      } catch (error) {
        context.commit("updateMessage", "Данные не сохранены", {
          root: true
        });
      }
      context.dispatch("getSquares");
    },
    async pushSquares(
      context,
      {
        id,
        geometry = null,
        name,
        address,
        area,
        year,
        plant_amount,
        foliage_amount,
        pine_amount,
        bush_amount,
        hedge_length,
        lawn_area,
        garden_area,
        architecture_amount,
        fountain_amount,
        beautification_amount,
        bench_amount,
        trashcan_amount,
        lamp_amount,
        irrigation_length,
        road_length,
        company,
        district_id
      }
    ) {
      const config = {};
      const body = { id: id };
      if (geometry) body.geometry = geometry;
      if (name) body.name = name;
      if (address) body.address = address;
      if (area) body.area = area;
      if (year) body.year = year;
      if (plant_amount) body.plant_amount = plant_amount;
      if (foliage_amount) body.foliage_amount = foliage_amount;
      if (pine_amount) body.pine_amount = pine_amount;
      if (bush_amount) body.bush_amount = bush_amount;
      if (hedge_length) body.hedge_length = hedge_length;
      if (lawn_area) body.lawn_area = lawn_area;
      if (garden_area) body.garden_area = garden_area;
      if (architecture_amount) body.architecture_amount = architecture_amount;
      if (fountain_amount) body.fountain_amount = fountain_amount;
      if (beautification_amount)
        body.beautification_amount = beautification_amount;
      if (bench_amount) body.bench_amount = bench_amount;
      if (trashcan_amount) body.trashcan_amount = trashcan_amount;
      if (lamp_amount) body.lamp_amount = lamp_amount;
      if (irrigation_length) body.irrigation_length = irrigation_length;
      if (road_length) body.road_length = road_length;
      if (company) body.company = company;
      if (district_id) body.district_id = district_id;
      try {
        await Vue.axios.post(`${URL_API}square/update`, body, config);
        context.commit("updateMessage", "Данные успешно сохранены", {
          root: true
        });
      } catch (error) {
        context.commit("updateMessage", "Данные не сохранены", {
          root: true
        });
      }
      context.dispatch("getSquares");
    },
    async destroySquares(context, id) {
      const config = {};
      const body = {
        id: id
      };
      try {
        await Vue.axios.post(`${URL_API}square/destroy`, body, config);
        context.commit("updateMessage", "Данные успешно удалены", {
          root: true
        });
      } catch (error) {
        context.commit("updateMessage", "Данные не удалены", {
          root: true
        });
      }
      context.dispatch("getSquares");
    }
  },
  mutations: {
    updateSquares(state, data) {
      state.squares = data;
    }
  },
  state: {
    squares: {}
  },
  getters: {
    allSquares(state) {
      return state.squares;
    }
  }
};

import Vue from "vue";

import { URL_API } from "../../settings/apiConnect";

export default {
  actions: {
    async getTaxatorGrid(context) {
      const response = await Vue.axios.get(`${URL_API}taxatorgrid`);
      let data = response["data"];
      context.commit("updateTaxatorGrid", data);
      return data;
    },
    async createTaxatorGrid(context, { geometry, name, user_ids }) {
      const config = {};
      let body = {};
      if (geometry) body.geometry = geometry;
      if (name) body.name = name;
      if (user_ids) body.user_ids = user_ids;

      try {
        await Vue.axios.post(`${URL_API}taxatorgrid/store`, body, config);
        context.commit("updateMessage", "Данные успешно сохранены", {
          root: true
        });
      } catch (error) {
        context.commit("updateMessage", "Данные не сохранены", { root: true });
      }
    },
    async pushTaxatorGrid(context, { id, geometry = null, name, user_ids }) {
      const config = {};
      const body = { id: id };
      if (geometry) body.geometry = geometry;
      if (name) body.name = name;
      if (user_ids) body.user_ids = user_ids;

      try {
        await Vue.axios.post(`${URL_API}taxatorgrid/update`, body, config);
        context.dispatch("getTaxatorGrid");
        context.commit("updateMessage", "Данные успешно сохранены", {
          root: true
        });
      } catch (error) {
        context.commit("updateMessage", "Данные не сохранены", { root: true });
      }
    },
    async destroyTaxatorGrid(context, id) {
      const config = {};
      const body = {
        id: id
      };
      try {
        await Vue.axios.post(`${URL_API}taxatorgrid/destroy`, body, config);
        context.dispatch("getTaxatorGrid");
        context.commit("updateMessage", "Данные успешно удалены", {
          root: true
        });
      } catch (error) {
        context.commit("updateMessage", "Данные не удалены", { root: true });
      }
    },
    async getCurrentTaxatorGrid() {
      const response = await Vue.axios.get(`${URL_API}taxatorgrid/getcurrent`);
      return response["data"];
    },
    async getTaxators(context) {
      const response = await Vue.axios.get(`${URL_API}user/getTaxators`);
      let data = response["data"];
      context.commit("updateTaxators", data);
      return data;
    }
  },
  mutations: {
    updateTaxatorGrid(state, data) {
      state.taxatorgrid = data;
    },
    updateTaxators(state, data) {
      state.allTaxators = data;
    }
  },
  state: {
    taxatorgrid: {},
    allTaxators: []
  },
  getters: {
    allTaxatorGrid(state) {
      return state.taxatorgrid;
    },
    allTaxators(state) {
      return state.allTaxators;
    }
  }
};

import Vue from "vue";

import { URL_API } from "../../settings/apiConnect";

export default {
  actions: {
    async getFlowerGardenData(context) {
      const response = await Vue.axios.get(`${URL_API}flowerGarden`);
      let data = response["data"];
      context.commit("updateFlowerGardenData", data);
    },
    async createFlowerGardenData(context, name) {
      const config = {};
      const body = {
        name: name
      };
      await Vue.axios.post(`${URL_API}flowerGarden/store`, body, config);
      context.dispatch("getFlowerGardenData");
    },
    async pushFlowerGardenData(context, { id, name }) {
      const config = {};
      const body = {
        id: id,
        name: name
      };
      await Vue.axios.post(`${URL_API}flowerGarden/update`, body, config);
      context.dispatch("getFlowerGardenData");
    },
    async destroyFlowerGardenData(context, id) {
      const config = {};
      const body = {
        id: id
      };
      await Vue.axios.post(`${URL_API}flowerGarden/destroy`, body, config);
      context.dispatch("getFlowerGardenData");
    }
  },
  mutations: {
    updateFlowerGardenData(state, FlowerGardenData) {
      state.FlowerGardenData = FlowerGardenData;
    }
  },
  state: {
    FlowerGardenData: []
  },
  getters: {
    allFlowerGardenData(state) {
      return state.FlowerGardenData;
    }
  }
};

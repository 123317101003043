import Vue from "vue";

import { URL_API } from "../../settings/apiConnect";

export default {
  state: {
    plant: {}
  },
  getters: {
    selectedPlant(state) {
      return state.plant;
    }
  },
  mutations: {
    updateSelectedPlant(state, data) {
      state.plant = data;
    }
  },
  actions: {
    async getSelectedPlant(ctx, id) {
      const res = await Vue.axios.get(`${URL_API}plant/view/${id}`);
      if (res.status < 300) {
        await ctx.commit("updateSelectedPlant", res.data);
      }
    }
  }
};

import Vue from "vue";

import { URL_API } from "../../settings/apiConnect";

export default {
  actions: {
    async getBreedsData(context) {
      const response = await Vue.axios.get(`${URL_API}breed`);
      let data = response["data"];
      context.commit("updateBreedsData", data);
      context.commit("updateFilteredBreeds", data);
    },
    async createBreedData(context, { name, parent_id, redbook }) {
      const config = {};
      const body = {
        name: name,
        parent_id: parent_id,
        redbook: redbook
      };
      await Vue.axios.post(`${URL_API}breed/store`, body, config);
      context.dispatch("getBreedsData");
    },
    async pushBreedData(context, { id, name, parent_id, redbook }) {
      const config = {};
      const body = {
        id: id,
        name: name,
        parent_id: parent_id,
        redbook: redbook
      };
      await Vue.axios.post(`${URL_API}breed/update`, body, config);
      context.dispatch("getBreedsData");
    },
    async destroyBreedData(context, id) {
      const config = {};
      const body = {
        id: id
      };
      await Vue.axios.post(`${URL_API}breed/destroy`, body, config);
      context.dispatch("getBreedsData");
    }
  },
  mutations: {
    updateBreedsData(state, breedsData) {
      state.breedsData = breedsData;
    }
  },
  state: {
    breedsData: []
  },
  getters: {
    allBreedsData(state) {
      return state.breedsData;
    }
  }
};

import Vue from "vue";

import { URL_API } from "../../settings/apiConnect";

export default {
  actions: {
    async getDistrictsData(context) {
      const response = await Vue.axios.get(`${URL_API}district`);
      let data = response["data"];
      context.commit("updateDistrictsData", data);
      context.commit("updateFilteredDisctricts", data);
      return data;
    },
    async createDistrictData(context, name) {
      const config = {};
      const body = {
        name: name
      };
      await Vue.axios.post(`${URL_API}district/store`, body, config);
      context.dispatch("getDistrictsData");
    },
    async pushDistrictData(context, { id, geometry = null, name, color }) {
      const config = {};
      const body = { id: id };
      if (geometry) body.geometry = geometry;
      if (name) body.name = name;
      if (color) body.color = color;

      try {
        await Vue.axios.post(`${URL_API}district/update`, body, config);
        context.dispatch("getDistrictsData");
        context.commit("updateMessage", "Данные успешно сохранены", {
          root: true
        });
      } catch (error) {
        context.commit("updateMessage", "Данные не сохранены", { root: true });
      }
    },
    async destroyDistrictData(context, id) {
      const config = {};
      const body = {
        id: id
      };
      await Vue.axios.post(`${URL_API}district/destroy`, body, config);
      context.dispatch("getDistrictsData");
    },
    async getDistrictByArea(context, wkt) {
      const response = await Vue.axios.get(
        `${URL_API}district/getbyarea?geometry=` + wkt
      );
      let data = response["data"];
      return data[0];
    }
  },
  mutations: {
    updateDistrictsData(state, districtsData) {
      state.districtsData = districtsData;
    }
  },
  state: {
    districtsData: {}
  },
  getters: {
    allDistrictsData(state) {
      return state.districtsData;
    }
  }
};

import Vue from "vue";

import { URL_API } from "../../settings/apiConnect";

export default {
  actions: {
    async getOther(context) {
      let response = await Vue.axios.get(
        `${URL_API}architecture/getbytype?architecture_type_id=7`
      );
      let data = response["data"];
      context.commit("updateOther", data);
      return data;
    }
  },
  mutations: {
    updateOther(state, data) {
      state.other = data;
    }
  },
  state: {
    other: {}
  },
  getters: {
    allOther(state) {
      return state.other;
    }
  }
};

import Vue from "vue";

import { URL_API } from "../../settings/apiConnect";

export default {
  actions: {
    async getUsersData(context) {
      const response = await Vue.axios.get(`${URL_API}user`);
      let data = response["data"];
      context.commit("updateUsersData", data);
    },
    async createUserData(
      context,
      { name, surname, patronym, email, district_id }
    ) {
      const config = {};
      const body = {
        name: name,
        surname: surname,
        patronym: patronym,
        email: email,
        district_id: district_id
      };
      const response = await Vue.axios.post(
        `${URL_API}user/store`,
        body,
        config
      );
      return response["data"].id;
    },
    async pushUserData(
      context,
      { id, name, surname, patronym, email, district_id }
    ) {
      const config = {};
      const body = {
        id: id,
        name: name,
        surname: surname,
        patronym: patronym,
        email: email,
        district_id: district_id
      };
      await Vue.axios.post(`${URL_API}user/update`, body, config);
      context.dispatch("getUsersData");
    },
    async destroyUserData(context, id) {
      const config = {};
      const body = {
        id: id
      };
      await Vue.axios.post(`${URL_API}user/destroy`, body, config);
      context.dispatch("getUsersData");
    },
    async addUserRole(context, { user_id, role_id }) {
      const config = {};
      const body = {
        user_id: user_id
      };
      if (role_id) body.role_id = role_id;
      await Vue.axios.post(`${URL_API}user/replaceRole`, body, config);
    },
    async userPassword(context, { id, password }) {
      const config = {};
      const body = {
        id: id
      };
      if (password) body.password = password;
      await Vue.axios.post(`${URL_API}user/update`, body, config);
    },
    async getLogUsers(context) {
      const response = await Vue.axios.get(`${URL_API}user/search`);
      let data = response["data"];
      context.commit("updateLogUsers", data);
    }
  },
  mutations: {
    updateUsersData(state, usersData) {
      state.usersData = usersData;
    },
    updateLogUsers(state, data) {
      state.logUsers = data;
    }
  },
  state: {
    usersData: [],
    logUsers: []
  },
  getters: {
    allUsersData(state) {
      return state.usersData;
    }
  }
};

import Vue from "vue";

import { URL_API } from "../../settings/apiConnect";

export default {
  actions: {
    async getRolesData(context) {
      const response = await Vue.axios.get(`${URL_API}role`);
      let data = response["data"];
      context.commit("updateRolesData", data);
    },
    async createRoleData(context, name) {
      const config = {};
      const body = {
        name: name
      };
      await Vue.axios.post(`${URL_API}role/store`, body, config);
      context.dispatch("getRolesData");
    },
    async destroyRoleData(context, id) {
      const config = {};
      const body = {
        id: id
      };
      await Vue.axios.post(`${URL_API}role/destroy`, body, config);
      context.dispatch("getRolesData");
    }
  },
  mutations: {
    updateRolesData(state, rolesData) {
      state.rolesData = rolesData;
    }
  },
  state: {
    rolesData: []
  },
  getters: {
    allRolesData(state) {
      return state.rolesData;
    }
  }
};
